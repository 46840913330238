<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog :value="showDialog" :persistent="false" width="600" @input="closeDialog">
      <v-card>
        <v-card-title class="headline">
          <span><i class="la" :class="{
            'la-arrow-right': action === 'move',
            'la-copy': action === 'copy'
          }" ></i> {{ action === 'move'? 'Переместить товар' : 'Копировать товар' }}</span>
        </v-card-title>

        <v-card-text>
          <form>
            <v-layout column>
              <v-row class="form-row">
                <v-col cols="12" class="form-column">
                  <ValidationProvider name="opposite_category_id" rules="required" v-slot="{invalid, validated}">
                    <v-select
                        v-model="opposite_category_id"
                        :items="categoriesListShort"
                        item-value="id"
                        item-text="name"
                        label="Категория *"
                        hide-details="auto"
                        required
                        :error="invalid && validated"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-spacer/>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="mr-2 fa fa-save"></i> <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from "@/views/grid_elements/mixins/EntityModalMixin";
import api from "@/api";
import {mapActions, mapState} from "vuex";
import {showMessage} from "@/lib/toasted";
import {Dealer, Subdivision} from "@/config/product_types";

export default {
  name: "MoveProductModal",
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  mounted() {
    this.fetchCategoryListShort(this.productType === Dealer ? Subdivision : Dealer)
  },
  props: {
    productType: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  mixins: [EntityModalMixin],
  data() {
    let state = {
      opposite_category_id: ''
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }

    return state;
  },
  computed: {
    ...mapState('catalog', ['categoriesListShort']),
    data() {
      return {
        name: this.name,
      }
    }
  },
  methods: {
    ...mapActions('catalog', ['fetchCategoryListShort']),
    async submit() {
      const method = this.action === 'move' ? api.catalog.moveProductToAnotherCatalog :
      api.catalog.copyProductToAnotherCatalog;

      let resp;

      try {
        resp = await method(this.currentItem.id, this.productType === Dealer ? Subdivision : Dealer, {category_id: this.opposite_category_id})
      } catch (e) {
        resp = e.response;
      }

      const {data: {status = null, message = ''}} = resp;
      showMessage(status, message);

      if (status === 'ok') {
        this.$emit('stored');
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
