var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":false,"width":"600"},on:{"input":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_c('span',[_c('i',{staticClass:"la",class:{
          'la-arrow-right': _vm.action === 'move',
          'la-copy': _vm.action === 'copy'
        }}),_vm._v(" "+_vm._s(_vm.action === 'move'? 'Переместить товар' : 'Копировать товар'))])]),_c('v-card-text',[_c('form',[_c('v-layout',{attrs:{"column":""}},[_c('v-row',{staticClass:"form-row"},[_c('v-col',{staticClass:"form-column",attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"opposite_category_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var invalid = ref.invalid;
        var validated = ref.validated;
return [_c('v-select',{attrs:{"items":_vm.categoriesListShort,"item-value":"id","item-text":"name","label":"Категория *","hide-details":"auto","required":"","error":invalid && validated},model:{value:(_vm.opposite_category_id),callback:function ($$v) {_vm.opposite_category_id=$$v},expression:"opposite_category_id"}})]}}],null,true)})],1)],1)],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"mr-2 fa fa-save"}),_vm._v(" "),_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }